import http from '../httpConfig';

const userRecovery = (data) => new Promise((resolve, reject) => {
  http.post('/remember_password?environmentUser=patient', { 'remember_password': data })
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      if (
        'response' in err
        && 'data' in err.response
        && 'errors' in err.response.data
      ) {
        reject(err.response.data.errors);
        return;
      }

      reject();
    });
});

export default userRecovery;
